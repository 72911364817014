import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ListView from '../views/listing.vue'
import cartView from '../views/cart.vue'
import payment_optionView from '../views/payment_option.vue'
import payment_detailsView from '../views/payment_details.vue'
import track_orderView from '../views/track_order.vue'
import product_detailsView from '../views/product_details.vue'
import profileView from '../views/profile.vue'
import registrationView from '../views/registration.vue'
import loginview from '../views/loginview.vue'
import order_received  from '../views/order_received.vue'

const routes = [
  {
    path: '/',
    name: 'register',
    component: registrationView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/listing',
    name: 'listing',
    component: ListView
  },
  {
    path: '/payment_option',
    name: 'payment_option',
    component: payment_optionView
  },
  {
    path: '/cart',
    name: 'cart',
    component: cartView
  },
  {
    path: '/payment_details',
    name: 'payment_details',
    component: payment_detailsView
  },
  {
    path: '/track_order',
    name: 'track_order',
    component: track_orderView
  },
  {
    path: '/product_details',
    name: 'product_details',
    component: product_detailsView
  },
  {
    path: '/profile',
    name: 'profile',
    component: profileView
  },
  {
    path: '/registration',
    name: 'registration',
    component: registrationView
  },
  {
    path: '/login',
    name: 'login',
    component: loginview
  },
  {
    path: '/order_received',
    name: 'order_received',
    component: order_received
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router