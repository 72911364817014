<template>
  <div class="about">
    <div class="app-content">
      <div class="side-app">

        <!-- PAGE-HEADER -->
        <div class="homepage-navbar bg-light shadow mb-auto p-3 bg-primary">
          <div class="d-flex align-items-center">
            <a href="#" class="link-dark text-truncate d-flex align-items-center gap-2" data-bs-toggle="offcanvas"
              data-bs-target="#location" aria-controls="location">
              <i class="icofont-medicine fs-2 text-white"></i>
              <span>
                <h6 class="fw-bold text-white mb-0">Healthline Distributers</h6>
                <p class="text-white text-truncate d-inline-block mb-0 w-75 align-bottom"> <i
                    class="icofont-ui-call  text-white"></i> 82087 03010 </p>
              </span>
            </a>

          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <form ref="form">

              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0 card-title">Login To Access</h3>
                </div>
                <div class="card-body">
                  <div class="row">
                  
                    <div class="bg-white border-bottom p-3">
                      <div class="d-flex gap-3 align-items-center">
                        <i class="bi bi-telephone fs-5 text-muted"></i>
                        <div class="fw">
                          <p class="m-0 small text-muted">Contact Number</p>
                          <input type="text" class="form-control" v-model="number" placeholder="Type Here">
                        </div>
                      </div>
                    </div> 
                    <div class="bg-white border-bottom p-3">
                      <div class="d-flex gap-3 align-items-center">
                        <i class="bi bi-file-earmark-medical fs-5 text-muted"></i>
                        <div class="fw">
                          <p class="m-0 small text-muted">Drugs Liscence No</p>
                          <input type="text" class="form-control" v-model="di_no" placeholder="Type Here">
                        </div>
                      </div>
                    </div>  <br>
                    <div class="offcanvas-footer shadow-sm d-flex gap-3">
                      <button @click="edit > 0 ? edit(edit) : savedata()" type="button"
                        class="btn bg-primary shadow-sm border btn-light text-white w-100 text-uppercase btn-lg fw-bold col">Login</button>
                        
                        <router-link to="/registration" class="link-dark">
                          <span class="btn bg-secondary shadow-sm border btn-light text-white w-100 text-uppercase btn-lg fw-bold col">Go To Register</span>
                        </router-link>
                         
                    </div>
                    <router-link to="/home" class="link-dark">
                      <div class="btn  shadow-sm border btn-light ">Skip To Main</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<style>
.fw {
  width: -webkit-fill-available;
}

.ml-5 {
  margin-left: 10px;
}
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import CustomButton from '@/components/CustomButton.vue';
import axios from 'axios';

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  },
  responseType: 'json'
};


export default {
  name: 'loginview',
  components: {
    CustomButton, Header
  },
  data() {
    return {
      name: '',
      number: '',
      di_no: '',
      edit: 0,
      email: '',
      state: '',
      pincode: '',
      city: '',
      gst: '',
      address: ''


    }
  },
  components: {
    Header
  },
  mounted() {
  },
  methods: {
    cancel() {
      var self = this;

      self.title = '';
      self.description = '';

    },


    savedata() {
      var self = this;
      const formData = new FormData(); 
      formData.append("number", this.number);  
      formData.append("di_no", this.di_no); 
      if (!this.number)
        this.$swal("Please Enter Registered Number");
       else if (!this.di_no)
        this.$swal("Please Enter Drugs Licence Number");
       else {
        axios.post('verifyuser', formData, config)
          .then(response => {
                var status = response.data.status; 
                if(status.indexOf("Failed") != -1){
                    this.$swal("Login Failed");
                }else{
                  this.$swal(status);  
                  if(response.data.token){
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('verified', response.data.verified);
                        this.$router.push({ name: "home", });
                  }
                }
          })
          .catch(error => {
            console.log('Error on Authentication: ' + error);
          });
      }


    }
  }
};
</script>