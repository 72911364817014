<template>

    <Header/>
    <body>
      <div class="track-order d-flex flex-column vh-100">
         <!-- navbar -->
         <div class="d-flex align-items-center gap-3 p-3 shadow-sm">
            <a href="order-received.html" class="text-primary"><i class="icofont-close fs-5"></i></a>
            <div>
               <h6 class="fw-bold mb-0">Order #131784939798052</h6>
               <p class="text-muted small m-0"><a href="order-detail.html" class="text-primary">View Details</a> - 12:25 PM</p>
            </div>
            <div class="d-flex align-items-center gap-2 ms-auto">
               <a class="toggle" href="#">
               <b class="bg-dark bg-opacity-75 rounded-circle user-icon">
               <i class="bi bi-list d-flex m-0 h4 text-white"></i>
               </b>
               </a>
            </div>
         </div>
         <!-- body -->
         <div class="my-auto overflow-auto vh-100">
            <!-- map -->
            <div class="ratio ratio-4x3 z-n1">
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501748.249032638!2d73.16727014620984!3d31.00740966166808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391964aa569e7355%3A0x8fbd263103a38861!2sPunjab!5e0!3m2!1sen!2sin!4v1674302805615!5m2!1sen!2sin" class="border-0 w-100" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <!-- content -->
            <div class="bg-white p-3">
               <div class="text-center">
                  <div class="d-flex justify-content-center mb-3">
                     <div class="bg-white rounded-circle shadow-sm p-2 tracking-time">
                        <span class="bg-white order-time">
                           <div class="text-center">
                              <h1 class="fw-bold m-0">25</h1>
                              <p class="text-muted small m-0">mins</p>
                           </div>
                        </span>
                     </div>
                  </div>
                  <p class="text-muted text-center mb-4">Delivery to Home<br>H.No. 2834 Street, 784 Sector, Ludhiana, Punjab</p>
               </div>
               <div class="card border-secondary-subtle rounded-3 shadow-sm overflow-hidden mb-4">
                  <div
                     class="card-header d-flex align-items-center justify-content-between border-secondary-subtle border-bottom">
                     <p class="m-0 text-black">3 items to be packed</p>
                     <a href="order-detail.html" class="text-primary">See all items</a>
                  </div>
                  <div class="card-body p-0">
                     <div class="d-flex align-items-center gap-3 p-3 border-bottom">
                        <div>
                           <span class="border rounded d-flex align-items-center justify-content-center p-2"><i class="icofont-fast-delivery fs-5 text-muted"></i></span>
                        </div>
                        <div class="text-truncate">
                           <h6 class="osahan-mb-1 fw-normal">We have <span class="fw-bold text-success">recieved</span> your order</h6>
                           <p class="text-muted small text-truncate m-0">Order Received</p>
                        </div>
                     </div>
                     <div class="d-flex align-items-center gap-3 p-3">
                        <div>
                           <span class="border rounded d-flex align-items-center justify-content-center p-2"><i class="icofont-delivery-time fs-5 text-muted"></i></span>
                        </div>
                        <div class="text-truncate">
                           <h6 class="osahan-mb-1 fw-normal">We'll assign a delivery partner soon</h6>
                           <p class="text-muted small text-truncate m-0">Food is Being Prepared</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div>
                  <h6 class="fw-bold mb-3">While you wait for your order...</h6>
                  <div class="rounded-4 ps-4 pt-4 shadow-sm d-flex gap-1 align-items-center bg-warning bg-gradient justify-content-between">
                     <div class="pb-4">
                        <h1 class="fw-bolder text-black display-5 mb-1">50% OFF</h1>
                        <p class="text-dark">Special Offer: Get 50% Cashback + <span class="text-success"><i class="bi bi-basket"></i> Free Delivery</span>
                        <b class="bg-primary px-1 rounded-1 small text-uppercase fw-bold text-white mt-1 d-inline-block">New Users</b></p>
                        <a href="listing.html" class="btn btn-light text-success fw-bold rounded-3 shadow-sm btn-sm border-0">SHOP NOW</a>
                     </div>
                     <img src="img/banner1.png" alt="" class="img-fluid mt-auto osahan-offer-banner">
                  </div>
               </div>
            </div>
         </div>
         <!-- footer -->
         <div class="mt-auto shadow-sm">
            <a href="#" data-bs-toggle="offcanvas" data-bs-target="#help"
               aria-controls="help" class="btn btn-primary w-100 rounded-0 text-uppercase btn-lg fw-bold">Help</a>
         </div>
      </div>
      <!-- offcanvas help -->
      <div class="offcanvas offcanvas-end bg-light border-0" tabindex="-1" id="help" aria-labelledby="helpLabel">
         <div class="offcanvas-header bg-primary shadow-sm d-flex align-items-center justify-content-start gap-3">
            <a href="#" data-bs-dismiss="offcanvas" aria-label="Close"><i
               class="bi bi-arrow-left text-white fs-5"></i></a>
            <h6 class="offcanvas-title text-uppercase text-white fw-bold" id="helpLabel">Help & Support</h6>
         </div>
         <div class="offcanvas-body p-0">
            <div class="bg-white my-3 shadow-sm">
               <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item">
                     <h2 class="accordion-header p-3" id="flush-headingOne">
                        <button class="accordion-button collapsed bg-white shadow-none text-dark p-0 h6 mb-0" type="button"
                           data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false"
                           aria-controls="flush-collapseOne">
                        Where is my Order
                        </button>
                     </h2>
                     <div id="flush-collapseOne" class="accordion-collapse collapse p-0"
                        aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body p-3 pt-0">
                           <p class="text-muted">You can track the status of your order on the order tracking
                              page.
                           </p>
                           <div class="w-50">
                              <a href="#" class="btn btn-primary w-100 py-2">Chat with us</a>
                              <p class="text-primary text-start small pt-2 m-0">Wait time under 2 min(s)</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="border-bottom p-3">
                     <h6 class="m-0">I want to modify the items in my order</h6>
                  </div>
                  <div class="border-bottom p-3">
                     <h6 class="m-0">I want to give delivery related instructions</h6>
                  </div>
                  <div class="accordion-item">
                     <h2 class="accordion-header p-3" id="flush-headingFour">
                        <button class="accordion-button collapsed bg-white shadow-none text-dark p-0 h6 mb-0" type="button"
                           data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false"
                           aria-controls="flush-collapseFour">
                        I want to cancel my order
                        </button>
                     </h2>
                     <div id="flush-collapseFour" class="accordion-collapse collapse p-0"
                        aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body p-3 pt-0">
                           <p class="text-muted">We recommend you to cancel your order before pickup. For more
                              queries:
                           </p>
                           <div class="w-50">
                              <a href="#" class="btn btn-primary text-uppercase w-100 py-2">Chat with us</a>
                              <p class="text-primary text-start small pt-2 m-0">Wait time under 2 min(s)</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="p-3">
                     <h6 class="m-0">I have payment and refund related issues</h6>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- sidebar nav -->
      <nav id="main-nav">
         <ul class="second-nav">
            <li class="osahan-user-profile bg-primary">
               <div class="d-flex align-items-center gap-2">
                  <img src="img/delivery-boy.jpg" alt="" class="rounded-pill img-fluid">
                  <div class="ps-1">
                     <h5 class="fw-bold text-white osahan-mb-1">Black Smith</h5>
                     <p class="text-white-50 m-0">+91 12345-67890</p>
                  </div>
               </div>
            </li>
             <li><router-link to="index"><i class="bi bi-house me-3"></i>Homepage</router-link></li>
            <li><router-link to="listing"><i class="bi bi-grid me-3"></i>Listing</router-link></li>
            <li><router-link to="product-detail"><i class="bi bi-file-break me-3"></i>Product Detail</router-link></li>
            <li><router-link to="cart"><i class="bi bi-basket me-3"></i>Cart</router-link></li>
            <li><router-link to="payment_option"><i class="bi bi-credit-card me-3"></i>Payment Option</router-link></li>
            <li><router-link to="payment_details"><i class="bi bi-stopwatch me-3"></i>Payment Time</router-link></li>
            <li><router-link to="track-order"><i class="bi bi-check-circle me-3"></i>Order Received</router-link></li>
            <li><router-link to="track_order"><i class="bi bi-truck me-3"></i>Track Order</router-link></li>
            <li><router-link to="track_delivery-boy"><i class="bi bi-person-vcard me-3"></i>Track Delivery Boy</router-link></li>
            <li><router-link to="profile"><i class="bi bi-person me-3"></i>Profile</router-link></li>
            <li><router-link to="order-detail"><i class="bi bi-receipt me-3"></i>Order Details</router-link></li>
            <li>
               <a href="#"><i class='bi bi-link me-3'></i>Example Multi Link</a>
               <ul>
                  <li><a href="#">Link</a></li>
                  <li><a href="#">Link</a></li>
               </ul>
            </li>
         </ul>
       <ul class="bottom-nav">
            <li class="home">
              <router-link to="index" class="text-primary">
                  <p class="h5 m-0"><i class="bi bi-house"></i></p>
                  Home
               </router-link>
            </li>
            <li class="cart">
               <router-link to="cart">
                  <p class="h5 m-0"><i class="bi bi-basket"></i></p>
                  Cart
               </router-link>
            </li>
            <li class="profile">
               <router-link to="profile">
                  <p class="h5 m-0"><i class="bi bi-person"></i></p>
                  Profile
               </router-link>
            </li>
         </ul>
      </nav>
      
    </body>

    </template>