<template>

   <div class="cart d-flex flex-column vh-100">
      <!-- navbar -->
      <div class="bg-white mb-auto">
         <div class="d-flex align-items-center gap-3 p-3 bg-primary">
            <a @click="$router.go(-1)" class="text-white"><i class="bi bi-arrow-left fs-5"></i></a>
            <div>
               <h6 class="fw-bold text-white mb-0">Review Cart</h6>
               <p class="text-white-50 small m-0">Healthline Distributers</p>
            </div>
            <div class="d-flex align-items-center gap-2 ms-auto">
               <router-link to="/profile" class="link-dark">
                  <div class="bg-dark bg-opacity-75 rounded-circle user-icon"><i
                        class="bi bi-person d-flex m-0 h4 text-white"></i></div>
               </router-link>
               <a class="toggle" href="#">
                  <b class="bg-dark bg-opacity-75 rounded-circle user-icon">
                     <i class="bi bi-list d-flex m-0 h4 text-white"></i>
                  </b>
               </a>
            </div>
         </div>

      </div>
      <!-- body -->
      <div class="my-auto vh-100 overflow-auto p-3">
         <!-- coupon 
            <a href="#" class="link-dark" data-bs-toggle="offcanvas" data-bs-target="#applycoupon"
               aria-controls="applycoupon">
               <div class="bg-white d-flex align-items-center gap-3 p-3 mb-2 shadow-sm rounded-3">
                  <i class="icofont-sale-discount icofont-2x"></i>
                  <div>
                     <h6 class="fw-bold text-black osahan-mb-1">Apply Coupons</h6>
                     <p class="small text-primary m-0">Save more with coupons available for you</p>
                  </div>
                  <div class="ms-auto"><i class="bi bi-chevron-right fs-5"></i></div>
               </div>
            </a>-->
         <!-- review items -->
         <div class="bg-white p-3 mb-2 shadow-sm rounded-3">
            <div class="d-flex align-items-center gap-3 mb-3">
               <i class="icofont-food-basket icofont-2x"></i>
               <div>
                  <h6 class="fw-bold text-black osahan-mb-1">Review Items</h6>
                  <p class="small text-muted m-0">{{ cartitems.length }} item</p>
               </div>
            </div>
            <div class="d-flex align-items-center gap-3 justify-content-between" v-for="item in cartitems"
               :key="item.id">
               
               <div class="me-auto">
                  <p class="osahan-mb-1">{{ item.foodname }}</p>
                  <p class="text-muted small m-0">₹.{{ item.price }}</p>
               </div>
               <div
                  class="osahan-count d-flex align-items-center justify-content-between border border-dark-subtle rounded-pill h6 m-0 p-1">
                  <span class="text-muted minus d-flex" @click="decreaseQuantity(item)"><i
                        class="icofont-minus-circle"></i></span>
                  <input type="text" class="lh-sm small text-black text-center box border-0" v-model="item.quantity">
                  <span class="text-muted plus d-flex" @click="increaseQuantity(item)"><i
                        class="icofont-plus-circle"></i></span>
               </div>
               <span class="m-0 text-primary total_amt" >₹ {{ parseInt(item.quantity) * parseInt(item.price) }}</span>
               <span style="display:none;" class="mrp_total">₹ {{ parseInt(item.quantity) * parseInt(item.mrp) }}</span>
            </div>
         </div>
         <div class="pt-3">
               <button @click="" class="btn btn-primary w-100 text-uppercase  text-white fw-bold">Update Cart</button>
            </div>
         <!-- bill -->
         <div class="bg-white p-3 mb-2 shadow-sm rounded-3">
            <h6 class="fw-bold text-black mb-3">Bill Details</h6>
            <div class="border-bottom">
               <div class="d-flex align-items-center justify-content-between mb-2">
                  <p class="m-0">MRP Total</p>
                  <p type="hidden" class="m-0">₹ {{ subTotalAmount }}</p>
               </div>

            </div>
            <div class="d-flex align-items-center justify-content-between pt-3">
               <h6 class="text-primary m-0">Final Total</h6>
               <h6 class="fw-bold text-primary m-0">₹ {{ cart_total }}</h6>
            </div>
         </div>

      </div>
      <!-- footer -->
      <div class="mt-auto shadow-sm border-top">
         <div class="bg-light d-flex align-items-center border-bottom justify-content-between px-3 py-2">
            <p class="m-0">Subtotal: ₹.{{ cart_total }}</p>
         </div>
         <div class="bg-white p-3">

            <div class="pt-3">
               <button  @click="confirmOrder" class="btn btn-success w-100 text-uppercase btn-lg fw-bold">Confirm Order</button>
            </div>
         </div>
      </div>
   </div>

</template>

<script>
import cart from '@/views/cart.vue'
import Sidebar from '@/components/Sidebar.vue'
import Canvases from '@/components/Canvases.vue'
import Header from '@/components/Header.vue'
import axios from 'axios';
import { mask } from 'vue-the-mask'

const config = {
   headers: {
      "Content-Type": "application/x-www-form-urlencoded"
   },
   responseType: 'json'
};
export default {
   name: 'cart',
   props:
   {
      // SelectedItem:Array,         
   },
   components: {
      Header
   },
   data() {
      return {
         cartitems: [],
         id: '',
         qty: '',
         cart_total: '0',
         totalitem: '0',
         subTotalAmount: 0
      }
   },

   mounted() {
      this.getCartData();
   },

   methods: {

      getImageUrlfood(img) {
         return `${this.$baseurl}public/uploads/product/${img}`;
      }
      ,

      increaseQuantity(item) {
         item.quantity++;
         this.totalBill();
      },
      decreaseQuantity(item) {
         if (item.quantity > 1) {
            item.quantity--;
         }
         this.totalBill();
      },
      totalprice(item) {
         var a = item.foodprice * item.quantity;
         // this.totalBill(a);
         return a; 
      },
      totalBill() {
         var total_here=0;
         var mrp_total_here=0;
         $(".total_amt").each(function() {
            total_here+=parseInt($(this).html().replace('₹','').trim());
         });
         $(".mrp_total").each(function() {
            mrp_total_here+=parseInt($(this).html().replace('₹','').trim());
         });
         this.cart_total=total_here;
         this.subTotalAmount=mrp_total_here;
      },
      confirmOrder() {

         var self = this;
         axios.post('ajaxConfirmOrder',{
            confirm:'Yes',
         }).then(function (response) {
            var status = response.data.status;
            var orderid = response.data.orderid;
            self.$swal(status);
            if(status.toLowerCase().indexOf("Confirmed") != -1)
               self.$router.push({ name: "order_received", query: { orderid:orderid } });
         });

      },
      getCartData() {

         var self = this;
         axios.get('getCartData').then(function (response) {
            self.cartitems = response.data.cartitems;
            self.cart_total = response.data.cart_total;
            self.subTotalAmount = response.data.subTotalAmount;
         });

      },
   }
}
</script>
