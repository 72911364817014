<template>

    <Header/>
    
     <div class="payment-option d-flex flex-column vh-100">
         <!-- navbar -->
         <div class="bg-white mb-auto shadow-sm border-bottom">
            <div class="d-flex align-items-center gap-3 p-3">
               <a href="payment-option.html" class="text-primary"><i class="bi bi-arrow-left fs-5"></i></a>
               <div>
                  <h6 class="fw-bold mb-0">Payment Details</h6>
                  <p class="text-muted small m-0">3 items. Total: 130</p>
               </div>
               <div class="d-flex align-items-center gap-2 ms-auto">
                  <a class="toggle" href="#">
                  <b class="bg-dark bg-opacity-75 rounded-circle user-icon">
                  <i class="bi bi-list d-flex m-0 h4 text-white"></i>
                  </b>
                  </a>
               </div>
            </div>
         </div>
         <!-- body -->
         <div class="my-auto vh-100 d-flex align-items-center justify-content-center p-3">
            <a href="order-received.html" class="link-dark">
               <div class="text-center p-4">
                  <div class="mb-5">
                     <span>
                     <img src="img/clock.gif" alt="" class="img-fluid w-50">
                     </span>
                  </div>
                  <h5 class="fw-bold lh-base">Open your UPI app to approve the payment request before the timer runs out</h5>
               </div>
            </a>
         </div>
         <!-- footer -->
         <div class=" text-center p-3">
            <p class="text-muted"><span class="text-dark fw-bold">Note:</span> Do not hit back button or close this<br>
               screen until the transaction is complete
            </p>
         </div>
         <div class="mt-auto p-3">
             <router-link to="/track_order" class="btn btn-primary w-100 text-uppercase btn-lg fw-bold">Track Order </router-link>
            <a href="payment-option.html" class="btn bg-white shadow-sm border btn-light text-primary w-100 text-uppercase btn-lg fw-bold mt-3">Cancel payment</a>
         </div>
      </div>
      <BottomMenu/>

<Canvases :name="name" :categoryData="categoryData" :sucbfoodData="sucbfoodData" />
<Sidebar />
</template>
<script>


// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Canvases from '@/components/Canvases.vue'
import BottomMenu from '@/components/BottomMenu.vue'
import Header from '@/components/Header.vue'

import axios from 'axios';
import { mask } from 'vue-the-mask'

const config = {
headers: {
   "Content-Type": "application/x-www-form-urlencoded"
},
responseType: 'json'
};
export default {
name: 'payment_details',
components: {
   Sidebar, Canvases,BottomMenu,Header
},
data() {
   return {
      singlfoodData: [],
      subfoodcatData: [],
      variationData: [],
      sucbfoodData: [],
      catname: [],
      name: '',
      categoryData: [],
      SelectedItem: [],
      totalitem: '0',
   }
},
mounted() {
   this.getallcategory();
   this.gettotalitem();
},
created() {
   if (this.$route.query.id > 0) {
      this.getsinglefoodlist(this.$route.query.id);
   }
},
methods: {


   getsinglefoodlist(id) {
      var self = this;
      axios.get('getproduct_data?id=' + id).then(function (response) {
         self.singlfoodData = response.data.singlefoodlist;
         self.sucbfoodData = response.data.sucbfood;
         self.name = response.data.catname[0].name;
         self.img = response.data.catname[0].img;

      })

   },
   getallcategory() {
      var self = this;
      axios.get('categorylist').then(function (response) {
         self.categoryData = response.data.categorylist;
      });
   },
   addtocart(id) {
      var self = this;
      axios.get('get_user_id').then(function (response) {
         let message = response.data.message;

         if (message === "success")
            axios.get('seve_iatems_in_database?id=' + id).then(function (response) {
               // self.SelectedItem.push( response.data.itemsdata);                                                                   
            });
         else {
            $("#homelocation").show();
         }
      });

   },
   hidelogin() {
      $("#homelocation").hide();

   },
   showsidebar() {
      $("#sidebar").show();
   },
   save_in_cart() {
      var self = this;
      axios.get('')

   },
   subcatfood(id) {
      var self = this;
      axios.get('getsubcatfoods?id=' + id).then(function (response) {
         self.singlfoodData = response.data.subfoodlist;
      });
   },

   getImageUrl(img) {
      return `${this.$baseurl}public/uploads/food/${img}`;
   }
   ,
   getImageUrlCat(img) {
      return `${this.$baseurl}public/uploads/category/${img}`;
   },
   gettotalitem() {

      var self = this;
      axios.get('gettotalitem').then(function (response) {
         self.totalitem = response.data.totalitemcount;
      });

   },
   show_all_data(id) {
      var self = this;
      self.$router.push({ name: "product_details", query: { id } });
      //this.$router,push({name:"payment_details", query:{id}});
   }

}
}
</script>