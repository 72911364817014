   <template>
        <div class="listing-navbar bg-primary d-flex align-items-center gap-3 shadow-sm mb-auto p-3">
         <a @click="$router.go(-1)"><i class="bi bi-arrow-left fs-5 text-white"></i></a>
            <a href="#" class="text-white" data-bs-toggle="modal" data-bs-target="#categories">
               <div class="d-flex align-items-center gap-2">
                  <img src="img/delivery-boy.jpg" alt="" class="rounded-pill img-fluid">
                  <div>
                     <h6 class="fw-bold text-white mb-0">{{user.name}} </h6>
                     <p class="text-white-50 small m-0">{{user.number}} </p>
                  </div>
               </div>
            </a>
            <div class="d-flex align-items-center gap-2 ms-auto">
               <a class="toggle hc-nav-trigger hc-nav-1" href="#" role="button" aria-controls="hc-nav-1">
               <b class="bg-dark bg-opacity-75 rounded-circle user-icon">
               <i class="bi bi-list d-flex m-0 h4 text-white"></i>
               </b>
               </a>
            </div>
         </div>
         <!-- body -->
         <div class="vh-100 my-auto overflow-auto p-3">
            <div class="mb-4 rounded-3 shadow-sm overflow-hidden">
               <div   class="bg-white border-bottom p-3">
                  <div class="d-flex gap-3 align-items-center">
                     <i class="bi bi-patch-check-fill fs-5 text-muted"></i>
                     <span>
                        <p class="m-0 small text-muted">Drugs Licence Number</p>
                        <p class="m-0">{{user.di_no}}</p>
                     </span>
                     <a href="#" class="text-primary ms-auto d-flex"><i class="bi bi-chevron-right fs-6"></i></a>
                  </div>
               </div>
               <div   class="bg-white border-bottom p-3">
                  <div class="d-flex gap-3 align-items-center">
                     <i class="bi bi-file-earmark-medical fs-5 text-muted"></i>
                     <span>
                        <p class="m-0 small text-muted">GST Number</p>
                        <p class="m-0">{{user.gst}}</p>
                     </span>
                     <a href="#" class="text-primary ms-auto d-flex"><i class="bi bi-chevron-right fs-6"></i></a>
                  </div>
               </div>
               <div class="bg-white border-bottom p-3">
                  <div class="d-flex gap-3 align-items-center">
                     <i class="bi bi-geo-alt-fill fs-5 text-muted"></i>
                     <span>
                        <p class="m-0 small text-muted">Address</p>
                        <p class="m-0">{{user.address}}</p>
                     </span>
                     <a href="#" class="text-primary ms-auto d-flex"><i class="bi bi-chevron-right fs-6"></i></a>
                  </div>
               </div>

               <div   class="bg-white p-3">
                  <div class="d-flex gap-3 align-items-center">
                     <i class="bi bi-geo-alt fs-5 text-muted"></i>
                     <span>
                        <p class="m-0 small text-muted">Pincode</p>
                        <p class="m-0">{{user.pincode}}</p>
                     </span>
                     <a href="#" class="text-primary ms-auto d-flex"><i class="bi bi-chevron-right fs-6"></i></a>
                  </div>
               </div>
            </div>
            <h5 class="fw-bold text-black mb-3">Past orders</h5>
            <div class="bg-white rounded-3 shadow-sm mb-3"  v-for="item in orders" :key="item.in">
               <div class="d-flex align-items-start justify-content-between border-bottom p-3">
                  <div>
                     <h6 class="fw-bold text-black osahan-mb-1">Order No {{ item.id }}</h6>
                     <p class="text-muted small mb-2"></p>
                     <a href="#" class="text-muted">₹ {{ item.amount }}<i class="icofont-rounded-right ms-1"></i></a>
                  </div>
                  <p class="bg-success bg-opacity-10 text-success rounded small px-2 py-1">{{ (item.order_status==0)?'New Order':'Processing' }}<i class="icofont-check-circled ms-1"></i></p>
               </div>
               <div class="p-3">
                  <p class="mb-1">{{ item.order_details }}
                  </p>
                  <p class="text-muted small text-info m-0"><i class="icofont-clock-time"></i> {{ item.iddate }}</p>
               </div>
            </div> 
            <!-- logout -->
            <a   @click="logout" class="link-dark">
               <div class="bg-primary d-flex align-items-center rounded-3 shadow-sm justify-content-between p-3">
                  <h6 class="fw-bold text-white text-uppercase m-0">Logout Options</h6>
                  <i class="icofont-rounded-right text-white h6 m-0"></i>
               </div>
            </a>
            <p class="pt-3 text-muted text-center small mb-0">App version 1.0.0</p>
         </div> 
     
     
</template>
<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Canvases from '@/components/Canvases.vue'
import Header from '@/components/Header.vue'
import axios from 'axios';

export default {
   name: 'listing',
   components: {
      Sidebar,
      Canvases,
      Header
   },
   data() {
      return {
         user: [],
         orders: [],
         var_count:0
      };
   },
   mounted() {
      this.loadBasicData();

   },
   methods: {
     
      loadBasicData(id) { 
         var self = this;
         axios.post('profile',{token:((localStorage['token'])?localStorage['token']:'')}).then(function (response) { 
            self.user = response.data.user; 
            self.orders = response.data.orders; 
         });

      } ,
      logout(){
      localStorage.removeItem('token');
      this.$router.push({ name: "/"});
   }
      
    
   }
};
</script>