<template>
  <div> 
    <div class="homepage-navbar bg-light shadow mb-auto p-3 bg-primary">
      <div class="d-flex align-items-center">
        <a href="#" class="link-dark text-truncate d-flex align-items-center gap-2" data-bs-toggle="offcanvas" data-bs-target="#location" aria-controls="location">
          <i class="icofont-clock-time fs-2 text-white"></i>
          <span>
            <h6 class="fw-bold text-white mb-0">Delivery in 16 minutes</h6>
            <p class="text-white-50 text-truncate d-inline-block mb-0 w-75 align-bottom">H.No. 2834 Street, 784 Sector, Ludhiana, Punjab</p>
          </span>
        </a>
        <div class="d-flex align-items-center gap-2 ms-auto">
          <router-link to="/profile" class="link-dark">
            <div class="bg-dark bg-opacity-75 rounded-circle user-icon"><i class="bi bi-person d-flex m-0 h4 text-white"></i></div>
          </router-link>
          <a class="toggle hc-nav-trigger hc-nav-1" href="#" role="button" aria-controls="hc-nav-1">
            <b class="bg-dark bg-opacity-75 rounded-circle user-icon">
              <i class="bi bi-list d-flex m-0 h4 text-white"></i>
            </b>
          </a>
        </div>
      </div>
      <div class="pt-3">
        <!-- search -->
        <a href="#" data-bs-toggle="offcanvas" data-bs-target="#searchoffcanvas" aria-controls="searchoffcanvas">
          <div class="input-group bg-white rounded-3 shadow-sm py-1">
            <input type="text" class="form-control bg-transparent border-0 rounded-0 px-3" placeholder="Search for atta, dal, coke and more" aria-label="Search for atta, dal, coke and more" aria-describedby="search">
            <span class="input-group-text bg-transparent border-0 rounded-0 pe-3" id="search"><i class="icofont-search-1"></i></span>
          </div>
        </a>
      </div>
    </div>
  </div>
  
</template>
