<template>
 
    <Header/>
      <div class="d-flex flex-column vh-100">
         <div class="order-received vh-100 d-flex align-items-center justify-content-center">
            <a href="!#" class="link-dark">
               <div class="text-center p-4">
                  <img src="../../public/img/order-received.png" alt="" class="img-fluid w-50 mb-4">
                  <div>
                     <h3 class="fw-bold">Order No {{ordernumber }} Recieved</h3>
                     <p class="text-muted">We will provide you update earlier.</p>
                  </div>
               </div>
            </a>
         </div>
          
      </div>
  
      <BottomMenu/>

<Canvases :name="name" :categoryData="categoryData" :sucbfoodData="sucbfoodData" />
<Sidebar />
</template>
<script>


// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Canvases from '@/components/Canvases.vue'
import BottomMenu from '@/components/BottomMenu.vue'
import Header from '@/components/Header.vue'

import axios from 'axios';
import { mask } from 'vue-the-mask'

const config = {
headers: {
   "Content-Type": "application/x-www-form-urlencoded"
},
responseType: 'json'
};
export default {
name: 'order_received',
components: {
   Sidebar, Canvases,BottomMenu,Header
},
data() {
   return {
      ordernumber: 0, 
   }
},
mounted() {
   
},
created() {
   if (this.$route.query.orderid > 0) {
      this.ordernumber=this.$route.query.orderid;
   }
},
methods: { 
}
}
</script>