<template>
     <button v-if="btn.includes('submit')" type="button" class="btn btn-primary btn-squared px-30">{{lable}}</button> 
     <button v-else type="button" class="btn btn-default   px-30  btn-squared btn-light ">{{lable}}</button> 
</template>

<script> 

export default {
    name:'Button',
    props:['btn','lable'] 
   
};
</script>