<template>
 <!-- location offcanvas -->
      <div class="offcanvas offcanvas-end border-0" tabindex="-1" id="location" aria-labelledby="locationLabel">
         <div class="offcanvas-header bg-primary d-flex align-items-center justify-content-start gap-3">
            <a href="#" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-arrow-left fs-5 text-white"></i></a>
            <h6 class="offcanvas-title text-white m-0" id="locationLabel">About Us</h6>
         </div>
         <div class="offcanvas-body">
            <!-- search -->
            
            <!-- Saved Address -->
            <div class="border-bottom py-3">
               <p class="text-black text-uppercase small">Our Address</p>
               <div class="d-flex align-items-center justify-content-between">
                  <div class="w-75">
                     <a href="#" class="link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
                        <div class="d-flex align-items-center gap-2 osahan-mb-1">
                           <i class="icofont-ui-home text-muted fs-6"></i>
                           <h6 class="fw-bold mb-0">Home</h6>
                        </div>
                        <p class="text-muted text-truncate mb-0 ps-4">Solapur</p>
                     </a>
                  </div>
                
               </div>
            </div>
            <!-- recent search -->
            <div class="py-3">
               <p class="text-black text-uppercase small">Our Contact</p>
               <a href="tel:8208703010" class="link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
                  <div class="d-flex align-items-center gap-2 osahan-mb-1">
                     <i class="icofont-lui-call text-muted fs-6"></i>
                     <h6 class="fw-bold mb-0">+91 8208703010</h6>
                  </div>
                  <p class="text-muted text-truncate mb-0 ps-4">Office</p>
               </a>
            </div>
         </div>
      </div>
      <!-- Search Offcanvas -->
      <div class="offcanvas offcanvas-bottom border-0 h-100" tabindex="-1" id="searchoffcanvas"
         aria-labelledby="searchoffcanvasLabel">
         <div class="offcanvas-header bg-primary">
            <div class="input-group bg-white rounded-3 border-0 p-0">
               <a href="#" class="input-group-text bg-transparent border-0 rounded-0" id="search" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-arrow-left fs-5"></i></a>
               <input type="text" class="form-control bg-transparent border-0 rounded-0" placeholder="Search for groceries and more" aria-label="Search for groceries and more" aria-describedby="search" value="Chips">
            </div>
         </div>
         <div class="offcanvas-body p-0">
            <div class="row g-0">
               <div class="col-12 border-bottom">
                  <h6 class="p-3 m-0">139 result for <span class="fw-bold">"Chips"</span></h6>
               </div>
            </div>
            <div class="row border-bottom g-0">
               <div class="col-6 border-end">
                  <div class="card bg-transparent border-0 p-3">
                     <a href="product-detail.html">
                     <img src="img/uncle-chips.png" alt="" class="img-fluid w-75 d-block mx-auto">
                     </a>
                     <div class="card-body p-0">
                        <small class="text-muted">Uncle Chipps</small>
                        <h6 class="card-title fw-bold">Spicy Treat Sweet Chipps</h6>
                        <p class="card-text text-muted">50 g</p>
                     </div>
                     <div
                        class="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0">
                        <h6 class="fw-bold m-0">$20</h6>
                        <a href="cart.html" class="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm">ADD</a>
                     </div>
                  </div>
               </div>
               <div class="col-6">
                  <div class="card bg-transparent border-0 p-3">
                     <a href="product-detail.html">
                     <img src="img/kurkure.png" alt="" class="img-fluid w-75 d-block mx-auto">
                     </a>
                     <div class="card-body p-0">
                        <small class="text-muted">Kurkura</small>
                        <h6 class="card-title fw-bold">Masala Munch Crips Chipps</h6>
                        <p class="card-text text-muted">40 g</p>
                     </div>
                     <div
                        class="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0">
                        <h6 class="fw-bold m-0">$10</h6>
                        <a href="cart.html" class="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm">ADD</a>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row border-bottom g-0">
               <div class="col-6 border-end">
                  <div class="card bg-transparent border-0 p-3">
                     <a href="product-detail.html">
                     <img src="img/lays-cream.png" alt="" class="img-fluid w-75 d-block mx-auto">
                     </a>
                     <div class="card-body p-0">
                        <small class="text-muted">Lay'S</small>
                        <h6 class="card-title fw-bold">Lay's Amarican Cream & Onion</h6>
                        <p class="card-text text-muted">73 g</p>
                     </div>
                     <div
                        class="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0">
                        <h6 class="fw-bold m-0">$30</h6>
                        <a href="cart.html" class="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm">ADD</a>
                     </div>
                  </div>
               </div>
               <div class="col-6">
                  <div class="card bg-transparent border-0 p-3">
                     <a href="product-detail.html">
                     <img src="img/lays-masala.png" alt="" class="img-fluid w-75 d-block mx-auto">
                     </a>
                     <div class="card-body p-0">
                        <small class="text-muted">Lay'S</small>
                        <h6 class="card-title fw-bold">Lay's India's Magic Masala Chips</h6>
                        <p class="card-text text-muted">50 g</p>
                     </div>
                     <div
                        class="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0">
                        <h6 class="fw-bold m-0">$20</h6>
                        <a href="cart.html" class="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm">ADD</a>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row border-bottom g-0">
               <div class="col-6 border-end">
                  <div class="card bg-transparent border-0 p-3">
                     <a href="product-detail.html">
                     <img src="img/lays-potato.png" alt="" class="img-fluid w-75 d-block mx-auto">
                     </a>
                     <div class="card-body p-0">
                        <small class="text-muted">Lay'S</small>
                        <h6 class="card-title fw-bold">Lay's Hot N Sweet Chiili Potato Chips</h6>
                        <p class="card-text text-muted">50 g</p>
                     </div>
                     <div
                        class="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0">
                        <h6 class="fw-bold m-0">$20</h6>
                        <a href="cart.html" class="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm">ADD</a>
                     </div>
                  </div>
               </div>
               <div class="col-6">
                  <div class="card bg-transparent border-0 p-3">
                     <a href="product-detail.html">
                     <img src="img/lays-hot-potato.png" alt="" class="img-fluid w-75 d-block mx-auto">
                     </a>
                     <div class="card-body p-0">
                        <small class="text-muted">Lay'S</small>
                        <h6 class="card-title fw-bold">Lay's India's Magic Masala Chips</h6>
                        <p class="card-text text-muted">100 g</p>
                     </div>
                     <div
                        class="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0">
                        <h6 class="fw-bold m-0">$70</h6>
                        <a href="cart.html" class="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm">ADD</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
     
      
        <div class="modal" id="categories" tabindex="-1" aria-labelledby="categoriesLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-sm modal-dialog-scrollable">
            <div class="modal-content border-0 rounded-4 h-75">
               <div class="modal-header border-0 px-4">
                  <div class="modal-title" id="categoriesLabel">
                     <h5 class="fw-bold text-black mb-1">{{name}} 
</h5>
                  <p class="mb-0">{{sucbfoodData}} categories</p>
                  </div>
               </div>
               <div class="modal-body border-top p-4" v-for="item in categoryData" :key="item.id">
                  <div class="row row-cols-3 gy-3">
                     <div class="col">
                        <a @click="listing(item.id)" class="link-dark">
                           <div class="card bg-transparent border-0 text-center">
                              <img :src="getImageUrlCat(item.img)" alt="" class="card-img-top rounded-4 mb-2">
                              <div class="card-body p-0">
                                 <p class="card-title m-0">{{item.name}}</p>
                              </div>
                           </div>
                        </a>
                     </div>
                    
                  </div>
               </div>
            </div>
         </div>
      </div>






       
      <div id="divid">

      </div>
      

         </template>

<script>
export default {
  name: 'Canvases' ,
      props:
      {
         name:String,
         catname:Array,
         sucbfoodData:Array,
         categoryData:Array,
         bd:Number,
         variation:Array,
         user:Array
      },data() {
       
      },
    
    methods: { 
      
        getImageUrlCat(img) {
         return `${this.$baseurl}public/uploads/category/${img}`;
         }
,
 listing(id)
      {
         this.$router.push({name :"listing", query: {id } });

      } ,

    }
 
}
</script>
