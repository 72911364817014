import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


axios.defaults.baseURL = 'https://health.rockandrolls.in/index.php/'; 
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;';
//axios.defaults.headers.common['Authorization'] =(localStorage['token'])?localStorage['token']:'';
const app = createApp(App);
app.config.globalProperties.$apiname = 'https://health.rockandrolls.in/index.php/';
app.config.globalProperties.$baseurl = 'https://health.rockandrolls.in/';
app.use(VueSweetalert2);
app.use(router);
app.mount('#app');